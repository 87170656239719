import { Mail, TrendingUp } from "lucide-react"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"

export default function Subscribe() {
    return (
        <div className="flex min-h-screen w-screen flex-col items-center justify-center relative bg-[#f0f5ff] ">
            <div className=" w-full ">
                {/* Main content */}
                <div className="flex flex-col items-center text-center mb-12">
                    <div className="bg-white p-4 rounded-full mb-6 shadow-sm">
                        <Mail className="w-10 h-10 text-blue-500" />
                    </div>

                    <h1 className="text-2xl md:text-4xl font-bold mb-4 text-black">
                        The best AI tools in your inbox
                    </h1>

                    <p className="text-gray-600 mb-6 max-w-md md:max-w-2xl">
                        dollar1.app has been curating affordable AI tools for solopreneurs and startups since 2023. Sign up and
                        you'll always have something valuable to boost your business.
                    </p>

                    <div className="flex flex-wrap w-full max-w-xs md:max-w-md gap-2 mb-4">
                        <Input
                            type="email"
                            placeholder="Your email..."
                            className="bg-white border-gray-200 flex-grow"
                        />
                        <Button className="bg-blue-500 hover:bg-blue-600 text-white">Sign me up</Button>
                    </div>

                    <div className="text-sm text-gray-500">
                        Great products have a story behind them. We'll connect the dots and make it make sense.
                        {/* <a href="#" className="text-blue-500 hover:underline">
                            Find out more
                        </a> */}
                    </div>
                </div>


            </div>
        </div>



    )
}