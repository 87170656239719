import { Clock, UserPlus, CreditCard, Film } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
export default function UpcomingAppCard({ app }) {
  return (
    <div className="group relative flex flex-col overflow-hidden rounded-lg border bg-background shadow-sm transition-all hover:shadow-md">
      <div className="absolute top-2 right-2 z-10">
        <Badge className="bg-amber-500 text-white hover:bg-amber-600 text-xs">
          Coming Soon
        </Badge>
      </div>
      <div className="aspect-[16/9] bg-gradient-to-br from-amber-50 to-amber-100 dark:from-amber-950/20 dark:to-amber-900/20 flex items-center justify-center p-6">
        <div className="p-4 bg-white dark:bg-slate-800 rounded-full text-amber-500">
          {app.icon}
        </div>
      </div>
      <div className="flex flex-col space-y-1.5 p-4">
        <div className="flex items-center justify-between">
          <h3 className="font-semibold">{app.name}</h3>
          <Badge variant="outline" className="text-xs">
            {app.category}
          </Badge>
        </div>
        <p className="text-sm text-muted-foreground line-clamp-2">
          {app.description}
        </p>
        <div className="flex items-center pt-2">
          <div className="flex items-center gap-1 text-sm text-muted-foreground">
            <Clock className="h-4 w-4" />
            <span>Launching {app.launchDate}</span>
          </div>
        </div>
      </div>
      <div className="p-4 pt-0 mt-auto">
        <Button variant="outline" className="w-full">
          Get Notified
        </Button>
      </div>
    </div>
  );
}
