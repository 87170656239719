import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Switch } from "@/components/ui/switch";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { format } from "date-fns";
import { cn } from "@/lib/utils";
import {
  CalendarIcon,
  User,
  Link as LinkIcon,
  Code,
  Activity,
  Mail,
  Phone,
  DollarSign,
  CheckCircle,
} from "lucide-react";
import { Card, CardDescription, CardHeader } from "@/components/ui/card";
import { toast } from "sonner";
import axios from "axios";

const SubmitApp = () => {
  const navigate = useNavigate();
  const [formStep, setFormStep] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formComplete, setFormComplete] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    role: "",
    website: "",
    status: "",
    launchDate: new Date(),
    startPrice: false,
    apiAvailable: false,
  });

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const nextStep = (event) => {
    event.preventDefault(); // Prevents page reload
    setFormStep((prev) => prev + 1);
  };

  const prevStep = (event) => {
    event.preventDefault();
    setFormStep((prev) => Math.max(0, prev - 1));
  };

  async function handleSubmit(event) {
    event.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await axios.post(
        `${import.meta.env.VITE_APP_BASE_URL}/v1/forms/app_submission_form`,
        formData
      );
      if (response.data.status) {
        setIsSubmitting(false);
        setFormComplete(true);
      } else {
        toast.error(response.data.message);
        navigate("/");
      }
    } catch (error) {
      console.error("Error submitting the idea:", error);
      setIsSubmitting(false);
    }
  }

  // Renderr page basde on completion sattus
  if (formComplete) {
    return (
      <div className="h-fit bg-background">
        <div className="w-full h-fit flex items-center justify-center p-6">
          <div className="glass-morphism rounded-2xl p-10 max-w-md w-full animate-fade-in flex flex-col items-center justify-center space-y-6">
            <div className="rounded-full bg-primary/10 p-4">
              <CheckCircle className="w-16 h-16 text-primary animate-float" />
            </div>
            <h1 className="text-3xl font-medium text-center">Thank You!</h1>
            <p className="text-center text-muted-foreground">
              Your app submission has been received. We'll get back to you soon.
            </p>
            <Button className="mt-6" onClick={() => navigate("/")}>
              Return Home
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-fit ">
      <Card className="h-full bg-background my-4">
        <div className="w-full h-fit pt-24 pb-16 px-6">
          <div className="max-w-4xl mx-auto">
            <CardHeader>
              <div className="mb-16 animate-slide-in">
                <div className="inline-block bg-primary/10 text-primary px-3 py-1 rounded-full text-sm font-medium mb-3">
                  Submit Your App
                </div>
                <h1 className="text-4xl md:text-5xl font-medium mb-3">
                  Let's get your app listed
                </h1>
                <CardDescription>
                  <p className="text-lg text-muted-foreground max-w-xl">
                    Fill out the form below to submit your application for
                    review.
                  </p>
                </CardDescription>
              </div>
            </CardHeader>

            <div className="relative">
              {/* Progress indicator */}
              <div className="hidden md:block absolute left-0 top-0 bottom-0 w-px bg-border">
                <div
                  className="absolute left-0 top-0 w-px bg-primary transition-all duration-500 ease-in-out"
                  style={{ height: `${Math.min(100, (formStep / 2) * 100)}%` }}
                ></div>
              </div>

              <div className="md:pl-12">
                <form onSubmit={handleSubmit} className="space-y-12">
                  {/* Step 1 - Basic Info */}
                  <div
                    className={cn(
                      "space-y-6 transition-all duration-500",
                      formStep === 0
                        ? "opacity-100"
                        : "opacity-0 absolute pointer-events-none"
                    )}
                  >
                    <div className="flex items-center space-x-3 mb-6">
                      <div className="flex items-center justify-center rounded-full bg-primary/10 p-2">
                        <User className="h-5 w-5 text-primary" />
                      </div>
                      <h2 className="text-2xl font-medium">About You</h2>
                    </div>

                    <div className="space-y-4">
                      <div className="space-y-2">
                        <Label htmlFor="name">Your Name</Label>
                        <Input
                          id="name"
                          placeholder="John Doe"
                          value={formData.name}
                          onChange={(e) =>
                            handleInputChange("name", e.target.value)
                          }
                          className="transition-all-200"
                        />
                      </div>

                      <div className="space-y-2">
                        <Label htmlFor="email">Email Address</Label>
                        <div className="relative">
                          <Mail className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-muted-foreground" />
                          <Input
                            id="email"
                            type="email"
                            placeholder="you@example.com"
                            value={formData.email}
                            onChange={(e) =>
                              handleInputChange("email", e.target.value)
                            }
                            className="pl-10 transition-all-200"
                          />
                        </div>
                      </div>

                      <div className="space-y-2">
                        <Label htmlFor="phone">
                          Phone Number (with country code)
                        </Label>
                        <div className="relative">
                          <Phone className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-muted-foreground" />
                          <Input
                            id="phone"
                            placeholder="+1 234 567 8900"
                            value={formData.phone}
                            onChange={(e) =>
                              handleInputChange("phone", e.target.value)
                            }
                            className="pl-10 transition-all-200"
                          />
                        </div>
                      </div>

                      <div className="space-y-2">
                        <Label>Your Role</Label>
                        <RadioGroup
                          value={formData.role}
                          onValueChange={(value) =>
                            handleInputChange("role", value)
                          }
                          className="grid grid-cols-1 sm:grid-cols-3 gap-3 pt-2"
                        >
                          {["Student", "Freelancer", "Startup"].map((role) => (
                            <div key={role} className="relative">
                              <RadioGroupItem
                                value={role}
                                id={role}
                                className="peer sr-only"
                              />
                              <Label
                                htmlFor={role}
                                className="flex flex-col items-center justify-between rounded-md border-2 border-muted bg-transparent p-4 hover:bg-muted/10 hover:border-primary/50 peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary transition-all-200"
                              >
                                <span className="text-base">{role}</span>
                              </Label>
                            </div>
                          ))}
                        </RadioGroup>
                      </div>
                    </div>
                  </div>

                  {/* Step 2 - App Details */}
                  <div
                    className={cn(
                      "space-y-6 transition-all duration-500",
                      formStep === 1
                        ? "opacity-100"
                        : "opacity-0 absolute pointer-events-none"
                    )}
                  >
                    <div className="flex items-center space-x-3 mb-6">
                      <div className="flex items-center justify-center rounded-full bg-primary/10 p-2">
                        <Code className="h-5 w-5 text-primary" />
                      </div>
                      <h2 className="text-2xl font-medium">App Details</h2>
                    </div>

                    <div className="space-y-4">
                      <div className="space-y-2">
                        <Label htmlFor="website">App Website</Label>
                        <div className="relative">
                          <LinkIcon className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-muted-foreground" />
                          <Input
                            id="website"
                            placeholder="https://yourapp.com"
                            value={formData.website}
                            onChange={(e) =>
                              handleInputChange("website", e.target.value)
                            }
                            className="pl-10 transition-all-200"
                          />
                        </div>
                      </div>

                      <div className="space-y-2">
                        <Label>App Status</Label>
                        <Select
                          value={formData.status}
                          onValueChange={(value) =>
                            handleInputChange("status", value)
                          }
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Select app status" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="idea">Idea Stage</SelectItem>
                            <SelectItem value="beta">Beta</SelectItem>
                            <SelectItem value="public">
                              Open to Public
                            </SelectItem>
                          </SelectContent>
                        </Select>
                      </div>

                      <div className="space-y-2">
                        <Label>Launch Date</Label>
                        <Popover>
                          <PopoverTrigger asChild>
                            <Button
                              variant={"outline"}
                              className="w-full justify-start text-left font-normal"
                            >
                              <CalendarIcon className="mr-2 h-4 w-4" />
                              {formData.launchDate ? (
                                format(formData.launchDate, "PPP")
                              ) : (
                                <span>Select date</span>
                              )}
                            </Button>
                          </PopoverTrigger>
                          <PopoverContent className="w-auto p-0">
                            <Calendar
                              mode="single"
                              selected={formData.launchDate}
                              onSelect={(date) =>
                                date && handleInputChange("launchDate", date)
                              }
                              initialFocus
                            />
                          </PopoverContent>
                        </Popover>
                      </div>

                      <div className="flex items-center justify-between">
                        <div className="space-y-0.5">
                          <Label
                            className="cursor-pointer"
                            htmlFor="startPrice"
                          >
                            Starting Price at $1
                          </Label>
                          <p className="text-sm text-muted-foreground">
                            Does your app have a $1 entry price point?
                          </p>
                        </div>
                        <div className="flex items-center space-x-2">
                          <DollarSign className="h-4 w-4 text-muted-foreground" />
                          <Switch
                            id="startPrice"
                            checked={formData.startPrice}
                            onCheckedChange={(checked) =>
                              handleInputChange("startPrice", checked)
                            }
                          />
                        </div>
                      </div>

                      <div className="flex items-center justify-between">
                        <div className="space-y-0.5">
                          <Label
                            className="cursor-pointer"
                            htmlFor="apiAvailable"
                          >
                            APIs Available
                          </Label>
                          <p className="text-sm text-muted-foreground">
                            Does your app offer public APIs?
                          </p>
                        </div>
                        <Switch
                          id="apiAvailable"
                          checked={formData.apiAvailable}
                          onCheckedChange={(checked) =>
                            handleInputChange("apiAvailable", checked)
                          }
                        />
                      </div>
                    </div>
                  </div>

                  {/* Navigation buttons */}
                  <div className="flex justify-between pt-6">
                    {formStep > 0 ? (
                      <Button
                        type="button"
                        variant="outline"
                        onClick={prevStep}
                        className="transition-all-200"
                      >
                        Previous
                      </Button>
                    ) : (
                      <div></div>
                    )}

                    {formStep < 1 ? (
                      <Button
                        type="button"
                        onClick={nextStep}
                        className="transition-all-200"
                      >
                        Next
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        className="transition-all-200"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Submitting..." : "Submit App"}
                      </Button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default SubmitApp;
