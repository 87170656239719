import React, { useState } from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import {
  Search,
  Sparkles,
  Clock,
  Star,
  TrendingUp,
  Briefcase,
  FileText,
  Users,
  Megaphone,
  DollarSign,
  Calendar,
  Share2,
  Edit,
  Video,
  BarChart,
  Mail,
  Image,
  MapPin,
  UserPlus,
  CreditCard,
  Film,
  MailIcon,
} from "lucide-react";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import SubmitIdea from "@/pages/SubmitIdea/SubmitIdea";
import SubmitApp from "@/pages/SubmitApp/SubmitApp";
import UpcomingAppCard from "@/components/UpcomingApps";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Footer } from "@/components/Footer";
import Subscribe from "@/pages/Subscribe/Subscribe";


export default function App() {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className="flex min-h-screen flex-col items-center justify-center relative">
      {/* Header */}
      <header className="sticky top-0 z-50 w-full border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
        <div className="container flex h-16 items-center justify-between px-4 md:px-24">
          {/* Logo */}
          <div className="flex items-center gap-2">
            <Link to={'/'} className="text-xl md:text-2xl font-bold">
              dollar1.app<span className="text-primary text-xs md:text-sm">(alpha)</span>
            </Link>
          </div>

          {/* Mobile Menu Button */}
          <button
            className="md:hidden text-black"
            onClick={() => setMenuOpen((prev) => !prev)}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>

          {/* Navigation Links */}
          <nav
            className={`${menuOpen ? "block" : "hidden"}
    absolute top-full left-0 w-full bg-background p-4 md:flex md:static md:w-auto md:p-0 md:bg-transparent flex flex-col md:flex-row items-start md:items-center gap-4 md:gap-6`}
          >
            <Link
              onClick={() => setMenuOpen(false)} // Close menu on click
              to={"/submit-app"}
              className="text-sm font-medium hover:text-primary underline"
            >
              Submit your app
            </Link>
            <Link
              onClick={() => setMenuOpen(false)} // Close menu on click
              to={"/submit-idea"}
              className="text-sm font-medium hover:text-primary underline"
            >
              Submit new idea
            </Link>
            <Link
              onClick={() => setMenuOpen(false)} // Close menu on click
              to={"/subscribe"}
              className="text-sm font-medium hover:text-primary flex items-center border border-gray-400 gap-2 p-2 rounded-full"
            >
              <MailIcon className="w-4 h-4" />
              Subscribe
            </Link>
          </nav>

        </div>
      </header>

      {/* Main Content */}
      <div className="min-h-screen">
        <Routes>
          <Route index element={<LandingPage />} />
          <Route path="submit-idea" element={<SubmitIdea />} />
          <Route path="submit-app" element={<SubmitApp />} />
          <Route path="subscribe" element={<Subscribe />} />
        </Routes>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
}



function LandingPage() {
  return (
    <main className="flex-1">
      {/* Hero Section */}
      <section className="w-full py-12 md:py-24 lg:py-32 bg-gradient-to-b from-blue-50 to-white dark:from-gray-900 dark:to-background relative overflow-hidden">
        <div className="absolute inset-0 bg-grid-slate-900/[0.04] bg-[center_top_-1px] dark:bg-grid-slate-400/[0.05] dark:bg-[center_top_-1px] pointer-events-none"></div>
        <div className="absolute top-0 right-0 -translate-y-12 translate-x-12 xl:translate-x-16 w-[300px] h-[300px] bg-primary/10 rounded-full blur-3xl"></div>
        <div className="absolute bottom-0 left-0 translate-y-12 -translate-x-12 xl:-translate-x-16 w-[300px] h-[300px] bg-blue-500/10 rounded-full blur-3xl"></div>
        <div className="container px-4 md:px-6 relative">
          <div className="flex flex-col items-center space-y-4 text-center">
            <div className="space-y-2">
              <h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl lg:text-6xl ">
                AI apps for Solopreneurs & Startups at just{" "}
                <span className="text-primary"> $1</span>
              </h1>
              <h2 className="text-xl font-medium text-muted-foreground md:text-2xl">
                Automate tasks, generate content, and grow your business with
                enterprise-grade AI tools
              </h2>
            </div>
            <div className="w-full max-w-2xl space-y-2">
              <div className="relative">
                <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
                <Input
                  type="search"
                  placeholder="Search for AI apps..."
                  className="w-full bg-background pl-8 pr-4"
                />
              </div>
              <div className="flex flex-wrap justify-center gap-2">
                <Badge
                  variant="outline"
                  className="cursor-pointer hover:bg-muted"
                >
                  Recruitment
                </Badge>
                <Badge
                  variant="outline"
                  className="cursor-pointer hover:bg-muted"
                >
                  Sales
                </Badge>
                <Badge
                  variant="outline"
                  className="cursor-pointer hover:bg-muted"
                >
                  Marketing
                </Badge>
                <Badge
                  variant="outline"
                  className="cursor-pointer hover:bg-muted"
                >
                  Content
                </Badge>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Categories Section */}
      <section className="w-full py-12 md:py-16 bg-slate-50 dark:bg-slate-900/50">
        <div className="container px-4 md:px-6">
          <div className="flex items-center justify-between mb-8">
            <h2 className="text-2xl font-bold tracking-tight">Categories</h2>
            <Link
              href="#"
              className="text-sm font-medium text-primary hover:underline"
            >
              View all categories
            </Link>
          </div>
          <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
            {categories.map((category) => (
              <div
                key={category.name}
                className="flex flex-col items-center justify-center p-6 bg-white dark:bg-slate-800 rounded-lg hover:shadow-md transition-all cursor-pointer border border-slate-200 dark:border-slate-700"
              >
                <div className="mb-3 p-3 bg-primary/10 text-primary rounded-full">
                  {category.icon}
                </div>
                <span className="text-sm font-medium text-center">
                  {category.name}
                </span>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Trending Apps Section */}
      <section className="w-full py-12 md:py-16 bg-background">
        <div className="container px-4 md:px-6">
          <div className="flex items-center justify-between mb-8">
            <div className="flex items-center gap-2">
              <TrendingUp className="h-5 w-5 text-primary" />
              <h2 className="text-2xl font-bold tracking-tight">
                Trending Apps
              </h2>
            </div>
            <Link
              href="#"
              className="text-sm font-medium text-primary hover:underline"
            >
              View all trending
            </Link>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {trendingApps.map((app) => (
              <AppCard key={app.id} app={app} />
            ))}
          </div>
        </div>
      </section>

      {/* Newly Created Apps Section */}
      <section className="w-full py-12 md:py-16 bg-muted/50">
        <div className="container px-4 md:px-6">
          <div className="flex items-center justify-between mb-8">
            <div className="flex items-center gap-2">
              <Sparkles className="h-5 w-5 text-primary" />
              <h2 className="text-2xl font-bold tracking-tight">
                Newly Created
              </h2>
            </div>
            <Link
              href="#"
              className="text-sm font-medium text-primary hover:underline"
            >
              View all new apps
            </Link>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {newApps.map((app) => (
              <AppCard key={app.id} app={app} />
            ))}
          </div>
        </div>
      </section>

      {/* Sponsored Apps Section */}
      <section className="w-full py-12 md:py-16 bg-background">
        <div className="container px-4 md:px-6">
          <div className="flex items-center justify-between mb-8">
            <div className="flex items-center gap-2">
              <Star className="h-5 w-5 text-primary" />
              <h2 className="text-2xl font-bold tracking-tight">
                Sponsored Apps
              </h2>
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {sponsoredApps.map((app) => (
              <AppCard key={app.id} app={app} sponsored={true} />
            ))}
          </div>
        </div>
      </section>

      {/* Upcoming Apps Section */}
      <section className="w-full py-12 md:py-16 bg-muted/50">
        <div className="container px-4 md:px-6">
          <div className="flex items-center justify-between mb-8">
            <div className="flex items-center gap-2">
              <Clock className="h-5 w-5 text-primary" />
              <h2 className="text-2xl font-bold tracking-tight">
                Upcoming Apps
              </h2>
            </div>
            <Link
              href="#"
              className="text-sm font-medium text-primary hover:underline"
            >
              View all upcoming
            </Link>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {upcomingApps.map((app) => (
              <UpcomingAppCard key={app.id} app={app} />
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="w-full py-12 md:py-24 lg:py-32 bg-gradient-to-br from-primary to-blue-600 text-primary-foreground">
        <div className="container px-4 md:px-6">
          <div className="flex flex-col items-center space-y-4 text-center">
            <div className="space-y-2">
              <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">
                Join the AI-Powered Startup Revolution
              </h2>
              <p className="mx-auto max-w-[700px] text-primary-foreground/80 md:text-xl">
                Ready to transform your business with affordable AI? Sign up
                today and start exploring the tools that can take your startup
                journey to the next level.
              </p>
            </div>
            <div className="space-x-4">
              <Button size="lg" variant="secondary">
                Browse Apps
              </Button>
              <Link href="/submit-app">
                <Button
                  size="lg"
                  variant="outline"
                  className="bg-transparent text-primary-foreground border-primary-foreground hover:bg-primary-foreground hover:text-primary"
                >
                  Submit Your App
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

function AppCard({ app, sponsored = false }) {
  return (
    <div className="group relative flex flex-col overflow-hidden rounded-lg border bg-background shadow-sm transition-all hover:shadow-md">
      {sponsored && (
        <div className="absolute top-2 right-2 z-10">
          <Badge variant="secondary" className="text-xs">
            Sponsored
          </Badge>
        </div>
      )}
      <div className="aspect-[16/9] bg-gradient-to-br from-primary/5 to-primary/10 flex items-center justify-center p-6">
        <div className="p-4 bg-white dark:bg-slate-800 rounded-full text-primary">
          {app.icon}
        </div>
      </div>
      <div className="flex flex-col space-y-1.5 p-4">
        <div className="flex items-center justify-between">
          <h3 className="font-semibold">{app.name}</h3>
          <Badge variant="outline" className="text-xs">
            {app.category}
          </Badge>
        </div>
        <p className="text-sm text-muted-foreground line-clamp-2">
          {app.description}
        </p>
        <div className="flex items-center pt-2">
          <div className="flex items-center gap-1 text-sm text-muted-foreground">
            <Star className="h-4 w-4 fill-primary text-primary" />
            <span>{app.rating}</span>
            <span className="mx-1">•</span>
            <span>{app.usageCount} uses</span>
          </div>
        </div>
      </div>
      <div className="p-4 pt-0 mt-auto">
        <a href={app?.redirectURL} target="_blank">
          <Button className="w-full" disabled={app?.redirectURL ? false : true}>
            {app?.redirectURL ? "Try for $1" : "Coming Soon"}
          </Button>
        </a>
      </div>
    </div>
  );
}

const categories = [
  { name: "Recruitment", icon: <Briefcase className="h-6 w-6" /> },
  { name: "Sales", icon: <DollarSign className="h-6 w-6" /> },
  { name: "Marketing", icon: <Megaphone className="h-6 w-6" /> },
  { name: "Content", icon: <FileText className="h-6 w-6" /> },
];

const upcomingApps = [
  {
    id: 11,
    name: "Remote Onboarding Assistant",
    category: "Recruitment",
    description:
      "Streamline remote employee onboarding with automated workflows and personalized training.",
    icon: <UserPlus className="h-8 w-8" />,
    launchDate: "Next Week",
  },
  {
    id: 12,
    name: "Virtual Business Card Exchange",
    category: "Sales",
    description:
      "Exchange digital business cards and track networking connections with AI-powered insights.",
    icon: <CreditCard className="h-8 w-8" />,
    launchDate: "This Month",
  },
  {
    id: 13,
    name: "Video Editing Suite",
    category: "Content",
    description:
      "Edit videos with AI-powered tools for trimming, transitions, captions, and more.",
    icon: <Film className="h-8 w-8" />,
    launchDate: "Coming Soon",
  },
  {
    id: 14,
    name: "Task-Based Scheduler",
    category: "Productivity",
    description:
      "Optimize your workflow with AI that schedules tasks based on priority, deadlines, and your work habits.",
    icon: <Clock className="h-8 w-8" />,
    launchDate: "Next Month",
  },
];

const trendingApps = [
  {
    id: 1,
    name: "AI-Powered Screening",
    category: "Recruitment",
    description:
      "Automatically screen resumes and identify top candidates based on job requirements.",
    icon: <FileText className="h-8 w-8" />,
    rating: 4.8,
    usageCount: "12.5k",
    redirectURL: "https://recruter.ai/",
  },
  {
    id: 2,
    name: "AI Lead Generator",
    category: "Sales",
    description:
      "Generate qualified leads for your business with AI-powered prospecting and outreach.",
    icon: <Users className="h-8 w-8" />,
    rating: 4.6,
    usageCount: "8.2k",
  },
  {
    id: 3,
    name: "Social Media Scheduler",
    category: "Marketing",
    description:
      "Schedule and optimize your social media posts across multiple platforms with AI assistance.",
    icon: <Share2 className="h-8 w-8" />,
    rating: 4.7,
    usageCount: "5.9k",
  },
  {
    id: 4,
    name: "AI Content Creator",
    category: "Content",
    description:
      "Generate high-quality blog posts, articles, and social media content tailored to your brand.",
    icon: <Edit className="h-8 w-8" />,
    rating: 4.9,
    usageCount: "15.3k",
  },
];

const newApps = [
  {
    id: 5,
    name: "Video Interview Analyzer",
    category: "Recruitment",
    description:
      "Analyze candidate interviews to assess communication skills, body language, and cultural fit.",
    icon: <Video className="h-8 w-8" />,
    rating: 4.5,
    usageCount: "2.1k",
  },
  {
    id: 6,
    name: "Sales Pipeline Tracker",
    category: "Sales",
    description:
      "Track and optimize your sales pipeline with AI-powered insights and forecasting.",
    icon: <BarChart className="h-8 w-8" />,
    rating: 4.3,
    usageCount: "1.8k",
  },
  {
    id: 7,
    name: "Email Marketing Automator",
    category: "Marketing",
    description:
      "Create, schedule, and optimize email campaigns with AI-generated content and analytics.",
    icon: <Mail className="h-8 w-8" />,
    rating: 4.4,
    usageCount: "3.2k",
  },
  {
    id: 8,
    name: "Graphic Design Studio",
    category: "Content",
    description:
      "Create professional graphics, logos, and social media images with AI-powered design tools.",
    icon: <Image className="h-8 w-8" />,
    rating: 4.2,
    usageCount: "1.5k",
  },
];

const sponsoredApps = [
  {
    id: 9,
    name: "Local SEO Optimizer",
    category: "Marketing",
    description:
      "Optimize your local SEO with AI-powered keyword research, content suggestions, and analytics.",
    icon: <MapPin className="h-8 w-8" />,
    rating: 4.7,
    usageCount: "7.3k",
  },
  {
    id: 10,
    name: "AI Calendar Manager",
    category: "Productivity",
    description:
      "Manage your calendar, schedule meetings, and optimize your time with AI assistance.",
    icon: <Calendar className="h-8 w-8" />,
    rating: 4.6,
    usageCount: "6.8k",
  },
];
