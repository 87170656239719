import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Switch } from "@/components/ui/switch";
import { cn } from "@/lib/utils";
import {
  User,
  Mail,
  Phone,
  Link as LinkIcon,
  Target,
  Lightbulb,
  CheckCircle,
  Puzzle,
} from "lucide-react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
} from "@/components/ui/card";
import axios from "axios";
import { toast } from "sonner";

const targetAudienceOptions = [
  "Students",
  "Professionals",
  "Small Businesses",
  "Enterprises",
  "Content Creators",
  "Developers",
  "Healthcare",
  "Education",
  "Entertainment",
  "Other",
];

const SubmitIdea = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formComplete, setFormComplete] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    problem: "",
    solution: "",
    targetAudience: "",
    similarApps: "",
    readyToBuy: true,
  });

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await axios.post(
        `${import.meta.env.VITE_APP_BASE_URL}/v1/forms/app_idea_form`,
        formData
      );
      if (response.data.status) {
        console.log("Response:", response.data);
        setIsSubmitting(false);
        setFormComplete(true);
      } else {
        toast.error(response.data.message);
        navigate("/");
      }
    } catch (error) {
      console.error("Error submitting the idea:", error);
      setIsSubmitting(false);
      // Handle the error as needed
    }
  }

  // Render page based on completion status
  if (formComplete) {
    return (
      <div className="min-h-screen bg-background">
        <div className="w-full min-h-screen flex items-center justify-center p-6">
          <div className="glass-morphism rounded-2xl p-10 max-w-md w-full animate-fade-in flex flex-col items-center justify-center space-y-6">
            <div className="rounded-full bg-primary/10 p-4">
              <CheckCircle className="w-16 h-16 text-primary animate-float" />
            </div>
            <h1 className="text-3xl font-medium text-center">Thank You!</h1>
            <p className="text-center text-muted-foreground">
              Your idea has been submitted. We'll review it and get back to you
              soon.
            </p>
            <Button className="mt-6" onClick={() => navigate("/")}>
              Return Home
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Card className="min-h-screen bg-background my-4">
      <div className="w-full min-h-screen pt-24 pb-16 px-6">
        <div className="max-w-4xl mx-auto">
          <div className="mb-12 animate-slide-in">
            <CardHeader>
              <div className="inline-block bg-primary/10 text-primary px-3 py-1 rounded-full text-sm font-medium mb-3 w-fit">
                Submit Your Idea
              </div>
              <h1 className="text-4xl md:text-5xl font-medium mb-3">
                Share your app idea with us
              </h1>
            </CardHeader>
            <CardDescription>
              <p className="text-lg text-muted-foreground max-w-xl">
                Tell us about your app idea and we'll help you bring it to life.
              </p>
            </CardDescription>
          </div>

          <CardContent>
            <div className="space-y-8">
              <form onSubmit={handleSubmit} className="space-y-8">
                {/* Contact Information */}
                <div className="space-y-6">
                  <div className="flex items-center space-x-3 mb-4">
                    <div className="flex items-center justify-center rounded-full bg-primary/10 p-2">
                      <User className="h-5 w-5 text-primary" />
                    </div>
                    <h2 className="text-2xl font-medium">
                      Contact Information
                    </h2>
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="space-y-2">
                      <Label htmlFor="name">Your Name</Label>
                      <Input
                        id="name"
                        placeholder="John Doe"
                        value={formData.name}
                        onChange={(e) =>
                          handleInputChange("name", e.target.value)
                        }
                        className="transition-all-200"
                        required
                      />
                    </div>

                    <div className="space-y-2">
                      <Label htmlFor="email">Email Address</Label>
                      <div className="relative">
                        <Mail className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-muted-foreground" />
                        <Input
                          id="email"
                          type="email"
                          placeholder="you@example.com"
                          value={formData.email}
                          onChange={(e) =>
                            handleInputChange("email", e.target.value)
                          }
                          className="pl-10 transition-all-200"
                          required
                        />
                      </div>
                    </div>

                    <div className="space-y-2 md:col-span-2">
                      <Label htmlFor="phone">
                        Phone Number (with country code)
                      </Label>
                      <div className="relative">
                        <Phone className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-muted-foreground" />
                        <Input
                          id="phone"
                          placeholder="+1 234 567 8900"
                          value={formData.phone}
                          onChange={(e) =>
                            handleInputChange("phone", e.target.value)
                          }
                          className="pl-10 transition-all-200"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Idea Details */}
                <div className="space-y-6 pt-4">
                  <div className="flex items-center space-x-3 mb-4">
                    <div className="flex items-center justify-center rounded-full bg-primary/10 p-2">
                      <Lightbulb className="h-5 w-5 text-primary" />
                    </div>
                    <h2 className="text-2xl font-medium">Your Idea</h2>
                  </div>

                  <div className="space-y-6">
                    <div className="space-y-2">
                      <Label htmlFor="problem">
                        What problem does your idea solve?
                      </Label>
                      <div className="relative">
                        <Puzzle className="absolute left-3 top-3 h-4 w-4 text-muted-foreground" />
                        <Textarea
                          id="problem"
                          placeholder="Describe the problem you're trying to solve..."
                          value={formData.problem}
                          onChange={(e) =>
                            handleInputChange("problem", e.target.value)
                          }
                          className="pl-10 min-h-24 transition-all-200"
                          required
                        />
                      </div>
                    </div>

                    <div className="space-y-2">
                      <Label htmlFor="solution">
                        How does your idea solve this problem?
                      </Label>
                      <Textarea
                        id="solution"
                        placeholder="Describe your solution..."
                        value={formData.solution}
                        onChange={(e) =>
                          handleInputChange("solution", e.target.value)
                        }
                        className="min-h-24 transition-all-200"
                        required
                      />
                    </div>

                    <div className="space-y-2">
                      <Label htmlFor="targetAudience">Target Audience</Label>
                      <Select
                        value={formData.targetAudience}
                        onValueChange={(value) =>
                          handleInputChange("targetAudience", value)
                        }
                      >
                        <SelectTrigger className="w-full">
                          <div className="flex items-center">
                            {formData.targetAudience ? (
                              <>
                                <Target className="mr-2 h-4 w-4" />{" "}
                                {formData.targetAudience}
                              </>
                            ) : (
                              <span className="text-muted-foreground flex items-center">
                                <Target className="mr-2 h-4 w-4" /> Select
                                target audience
                              </span>
                            )}
                          </div>
                        </SelectTrigger>
                        <SelectContent>
                          {targetAudienceOptions.map((audience) => (
                            <SelectItem key={audience} value={audience}>
                              {audience}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>

                    <div className="space-y-2">
                      <Label htmlFor="similarApps">
                        URLs of Similar Apps (if any)
                      </Label>
                      <div className="relative">
                        <LinkIcon className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-muted-foreground" />
                        <Input
                          id="similarApps"
                          placeholder="https://example.com, https://another-example.com"
                          value={formData.similarApps}
                          onChange={(e) =>
                            handleInputChange("similarApps", e.target.value)
                          }
                          className="pl-10 transition-all-200"
                        />
                      </div>
                      <p className="text-sm text-muted-foreground mt-1">
                        Separate multiple URLs with commas
                      </p>
                    </div>

                    <div className="flex items-center justify-between">
                      <div className="space-y-0.5">
                        <Label className="cursor-pointer" htmlFor="readyToBuy">
                          Ready to buy if available
                        </Label>
                        <p className="text-sm text-muted-foreground">
                          Would you purchase this app if it already existed?
                        </p>
                      </div>
                      <Switch
                        id="readyToBuy"
                        checked={formData.readyToBuy}
                        onCheckedChange={(checked) =>
                          handleInputChange("readyToBuy", checked)
                        }
                      />
                    </div>
                  </div>
                </div>

                {/* Submit button */}
                <div className="pt-6">
                  <Button
                    type="submit"
                    className="w-full md:w-auto transition-all-200"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                  >
                    {isSubmitting ? "Submitting..." : "Submit Your Idea"}
                  </Button>
                </div>
              </form>
            </div>
          </CardContent>
        </div>
      </div>
    </Card>
  );
};

export default SubmitIdea;
